import './App.css';
import React, { useState } from 'react';
import Iphone from './Iphone.png';






function App() {
  
  const [inputValueEmail, setInputValueEmail] = useState('');
  const [inputValueName, setInputValueName] = useState('');

  

  const handleChangeEmail = (e) => {
    setInputValueEmail(e.target.value);
  };

  const handleChangeName = (e) => {
    setInputValueName(e.target.value);
  };



    

  
  return (
    <div className="App">
      <form>
        <div id="Container">
          <div id="Main">
            <div>
              <h1>MomentumSki</h1>
            </div>
            <div id="Submission-Container">
              <div id="Name">
                <input name="name" id="Input" type="text" placeholder="Name" onChange={handleChangeName} value={inputValueName} required/>
              </div>
              <div id="Email">
                <input name="email" id="Input" type="email" placeholder="Email" onChange={handleChangeEmail} value={inputValueEmail} required/>
                <input id="Submit-Button" type="submit" value="Submit"/>
                
              </div>
            </div>
            
          </div>
          <div id="Iphone">
            <img src={Iphone} draggable="false" alt="Iphone"/>
            
          </div>
          
        </div>
      </form>

        
      </div>
     
    
  );
}

export default App;
